

@tablet:    ~"only screen and (max-width: 980px)";
@mobile:    ~"only screen and (max-width: 600px)";
@unit: 12px; 
@bar-h: @unit * 4;
@color-panel: '#ffffff';
@panel-h: @bar-h * 9;

* {
  margin: 0;
  padding: 0;
}

@keyframes up {
  from {
    transform: translateY(50%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

html {
  font-size: 62.5%;
  height: 100%;
  body {
    font-size: 1.6rem;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    height: 100%;
  }
}

body {
  opacity: 0;
  &.loading {
    cursor: wait;
    * {
      pointer-events: none !important;
    }
  }
  &.anim {
    opacity: 0;
    transform: translateY(50%);
  }
  &.read {
    opacity: 1;
    transform: none;
  }
  &.show {
    opacity: 1;
  }
  &.loaded {
    opacity: 1;
    animation: up 1s normal forwards ease-in-out;
    #Showcase {
      animation: fadeIn 1s normal forwards ease-in-out;
    }
  }
}


*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}

.global-text {
  font-family: 'swis721', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  color: #333333;
  line-height: 1.2rem;
  letter-spacing: 0.06em;
  font-weight: normal;
  text-transform: uppercase;
}

button {
  background: none;
  border: none;
  cursor: pointer;
}

.uppercase {
  letter-spacing: .08em;
  text-transform: uppercase;
  font-size: 11px !important;
  @media @mobile {
    font-size: 10px !important;
  }
}

.normal-case {
  font-size: 1rem !important;
  //line-height: 1.2rem !important;
  letter-spacing: 0.06em !important;
  text-transform: none;
}

a {
  color: #333333;
  text-decoration: none;
}

.mobile {
  display: none;
  @media @tablet {
    display: block;
  }
}

.desktop {
  @media @tablet {
    display: none;
  }
}

.keyframes(@name; @arguments) {
	@-moz-keyframes @name { @arguments(); }
	@-webkit-keyframes @name { @arguments(); }
	@keyframes @name { @arguments(); }
}

.animation(@arguments) {
	-webkit-animation: @arguments;
	-moz-animation: @arguments;
	animation: @arguments;
}

body {
  &.show-index {
    @media @mobile {
      overflow: hidden;
    }
  }
}

h1, h2, h3, h4, h5, h6, p {
  .global-text();
  margin: 0;
  padding: 0;
}

img {
  display: block;
}

header {
  text-align: center;
  height: @bar-h;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  .loaded & {

  }
  h1 {
    width: 100%;
    
    a {
      line-height: @bar-h;
      display: block;
      font-size: 11px !important;
      font-family: 'Times';
      //width: 85px;
      text-decoration: none;
      margin: 0 auto;
      .project-info-visible & {
        width: 100%;
      }
      span {
        font-family: 'Times';
        height: @unit;
        line-height: px;
        display: inline-block;
        svg {
          width: auto;
          height: @unit;
          top: 2px;
          position: relative;
        }
      }
    }
  }
}

#Showcase {
  
}

.projects {
  padding-top: @bar-h;
  white-space: nowrap;
  overflow-x: scroll;
  width: 100%;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  //transition: 1s all ease-in-out;
  &:after {
    content: '';
    position: fixed;
    width: @unit;
    pointer-events: none;
    height: 100%;
    left: 50%;
    margin-left: -@unit/2;
    z-index: 1;
    top: 0;
    background: #fff;
    @media @mobile {
      width: @unit/2;
      margin-left: -@unit/4;
    }
    .show-index &,
    .show-about & {
      display: none;
    }
  }
  &::-webkit-scrollbar { width: 0 !important; }
  opacity: 0;
  .loaded &, .read & {
    opacity: 1;
  }
  .show-index[data-index-mode="list"] &,
  .show-about & {
    opacity: 0;
    pointer-events: none;
  }
  .project-image-holder {
    width: 100%;
    height: 100vh;
    height: calc(100vh - 96px);
    display: flex !important;
    .show-index &,
    .show-about & {
      //height: calc(100vh - (345px));
      transition: .3s height;
      &:hover {
        opacity: .8;
        cursor: pointer;
      }
      &:last-child {
        margin-right: 10px;
      }
    }
    .show-index &,
    .show-about & {
      width: auto;
      display: inline-block !important;
    }
    @media @mobile {
      height: auto;
    }
    img {
      display: none;
      @media @mobile {
        display: block;
        width: 100%;
        opacity: 0;
      }
      .show-index[data-index-mode="thumbs"] & {
        display: block;
        height: calc(100vh - (345px));
        width: auto;
      }
    }
    .project-image {
      //position: absolute;
      width: 50%;
      //margin: 0 @bar-h;
      height: 100%;
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
      .show-meta & {
        //width: calc(50% - 180px);
      }

      &.blank {
        .show-index &,
        .show-about & {
          display: none;
        }
      }

      .show-index[data-index-mode="list"] & {
        //height: calc(100vh - (345px));
        width: auto !important;
        img {
          display: block;
          opacity: 0;
        }
      }

      .show-index[data-index-mode="thumbs"] & {
        background: none !important;
        width: auto;
        margin-left: 0px;
        margin-right: 0px !important;
        display:inline-block;
        img {
          margin: 0 !important;
        }
      }
      
      &:nth-child(odd) {
        background-position-x: right;
        margin-left: @unit;
        //margin-right: 0;
        @media @mobile {
          margin-left: @unit/2;
        }
        .show-index &,
        .show-about & {
          margin-left: 10px;
        }
      }
      &:nth-child(even) {
        background-position-x: left;
        margin-right: @unit;
        &:before {
          width: 50%;
          height: 100%;
          background: #fff;
          position: fixed;
          //content: '';
          display: block;
        }
        .show-meta & {
          margin-left: 320px;
        }
        .show-index &,
        .show-about & {
          //margin-left: 0;
        }
        
      }
      img {
        //width: 100%;
        //visibility: hidden;
      }
    }
  }
}




.project-meta {
  width: 10px;
  height: 100%;
  background-color: #fff;
  left: 50%;
  position: absolute;
  top: 0;
  margin-left: -5px;
  .show-index &,
  .show-about & {
    display: none;
  }
}

.slick-arrow {
  position: absolute;
  z-index: 1;
  display: none !important;
}

.slick-track {
  transition-delay: 0ms;
}

.arrow {
  position: absolute;
  background: none;
  border: none;
  top: 50%;
  z-index: 2;
  display: none !important;
  svg {

  }
}
.right-arrow {
  right: 0;
}
.left-arrow {
  transform: rotate(180deg);
}

#About {
  background: #fff;
  height: @bar-h;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;  
  overflow: -moz-scrollbars-none;  
  &::-webkit-scrollbar { display: none; }

  -webkit-overflow-scrolling: auto;
  z-index: 2;

  #Project-Nav {
    position: absolute;
    right: 10rem;
    @media @tablet {
      display: none;
    }
    button {
      position: relative;
      padding: 0 5px;
      
      .arrow {
        top: -.1rem;
        display: inline-block !important;
        position: relative;
      }
    }
    .show-about &, .show-index & {
      display: none;
    }
  }

  #Index-Modes {
    position: fixed;
    right: 8rem;
    display: none;
    bottom: 0;
    .show-index & {
      display: block;
    }
    @media @tablet {
      display: none;
    }
    img {
      width: 16px;
      height: auto;
      display: inline-block;
    }
    #Button-Index-List {
      position: relative;
      top: 1px;
    }
    #Button-Index-Thumbs {
      margin-top: -2px;
    }
    button {
      position: relative;
      padding: 0 5px;
      
      .arrow {
        top: -.1rem;
        display: inline-block !important;
        position: relative;
      }
    }
  }
  
  .show-about &, .show-index & {
    height: @panel-h;
    background-color: @color-panel;
    @media @mobile {
      height: 70%;
    }
    //transition: .4s height;
  }
  .show-index[data-index-mode="thumbs"] & {
    height: 100%;
    
  }
  .about-inner {
    width: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;  
    overflow: -moz-scrollbars-none;  
    &::-webkit-scrollbar { display: none; }
    -webkit-overflow-scrolling: touch;
    margin-top: @bar-h;
    padding-top:@bar-h;
    display: none;
    .show-about & {
      display: flex;
      @media @mobile {
        flex-direction: column;
        padding-top:0;
        margin-top: @bar-h;
        //margin-top: @unit;
      }
    }
    .about-content {
      max-width: 300px;
      &:first-child {
        margin-left: @unit;
        @media @mobile {
          margin-left: @unit/2; 
        }
      }
      @media @mobile {
        margin-left: @unit/2; 
      }
      &:last-child {
        @media @mobile {
          margin-bottom: @bar-h;
        }
      }
    }
    .rte {
      max-width: @unit * 45;
      margin-right: @unit;
      @media @mobile {
        //margin-right: @unit/2;
      }
      p {
        margin-bottom: 15px;
        letter-spacing: .04em;
        line-height: 16px;
        font-size: 12px;
        text-transform: none;
        @media @mobile {
          font-size: 1rem;
          line-height: 1.2rem;
        }
      }
    }
  }
  nav {
    position: fixed;
    height: @bar-h;
    width: 100%;
    bottom: 0;
    left: 0;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0.98) 72%, rgba(255, 255, 255, 0) 100%);
    z-index: 1;
    button {
      position: fixed;
      
      padding: 0 @unit*1.75;
      bottom: 0;
      .global-text();
      .uppercase();
      line-height: @bar-h;
      &:hover {
        opacity: .7;
        @media @mobile {
          opacity: 1;
        }
      }
    }
    #Button-About {
      left: 0;
      z-index: 2;
    }
    #Button-Index {
      right: 0;
      z-index: 1;
    }
    #Button-Meta {
      left: 50%;
      transform: translateX(-50%);
      text-transform: uppercase;
      width: 320px;
      text-align: center;
      position: relative;
      //display: flex;
      @media @mobile {
        width: 100%;
      }
      //justify-content: space-between;
      #Project-Num-Count {
        position: absolute;
        left: 0;
        @media @mobile {
          position: absolute;
          left: 0;
          bottom: 18px;
          width: 100%;
          text-align: center;
        }
      }
      #Project-Read-More {
        position: absolute;
        right: 0;
        bottom: 0;
        .normal-case();
        @media @mobile {
          display: none;
        }
      }
      #Project-Count {
        position: absolute;
        right: -50px;
      }
      .show-about &, .show-index & {
        display: none;
      }
    }
  }
}

.project-text-holder {
  width: @unit * 30;
  background: #fff;
  position: fixed;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  display: none;
  z-index: 1;
  @media @mobile {
    width: 160px;
    //top: 50%;
    //height: calc(50vh - @bar-h);
    transform: none;
    width: 100%;
    left: 0;
    position: relative;
    padding-top: @unit;
    //display: block;
    &:after {
      content:'';
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 1;
      pointer-events: none;
      height: @bar-h * 2;
      background: rgb(255,255,255);
      background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9037990196078431) 49%, rgba(255,255,255,0) 100%);
    }
  }
  p {
    text-transform: none;
    font-size: 1rem;
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .show-meta & {
    display: block;
  }
  .project-text {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: relative;
    @media @mobile {
      display: flex;
      //min-height: 50vh;
      margin-bottom: @bar-h * 2;
    }
  }
  .project-text-top {
      position: absolute;
      top: @bar-h;
      left: 0;
      width: 100%;
      @media @mobile {
        //width: 100%;
        top: 0;
        width: 50%;
        position: relative;
      }
      .meta {
        display: flex;
      }
      .meta-title {
        margin-left: @unit;
        //width: @unit * 5;
        min-width: @unit * 5;
        .global-text();
        @media @mobile {
          margin-left: @unit/2;
        }
      }
  }
  .project-text-bottom {
      position: absolute;
      bottom: @bar-h;
      right: 0;
      width: 50%;
      @media @mobile {
        bottom: @bar-h * 1.5;
        width: 50%;
        bottom: auto;
        top: 0;
        position: relative;
      }
      p {
        margin-right: @unit;
      }
  }
}

.projects {
  //height: calc(100vh - @bar-h);
  .project-image {
    //height: calc(100vh - @bar-h);
    width: 100% !important;
    background-size: contain;
    .show-meta & {
      transform: translateX(-173px);

      @media @mobile {
        transform: none;
        //transform: translateX(-74px);
        
      }
    }
  }
}

.project-right {
  .project-image {
    background-position-x: left !important;
    margin-left: @unit/2 !important;
    margin-right: @unit;
    @media @mobile {
      margin-left: @unit/4 !important;
      margin-right: @unit/2;
    }
    .show-meta & {
      transform: translateX(174px);
      @media @mobile {
        transform: none;
        //transform: translateX(72px);
      }
    }
  }
}

.project-left {
  .project-image {
    margin-right: @unit/2 !important;
    @media @mobile {
      margin-right: @unit/4 !important;
    }
  }
  .show-index &, .show-about & {
    width: 100%;
  }
}

.project-left, .project-right {
  width: 50%;
}

.project-right {
  position: absolute;
  right: 0;
  top: 0;
  .show-index &, .show-about & {
    display: none;
  }
}

// Index 

.projects-index {
  width: 100%;
  height: calc(100% - (@panel-h));
  position: fixed;
  z-index: 9;
  top: @bar-h;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;  // IE 10+
  scrollbar-width: none;  // Firefox
  display: none;
  @media @mobile {
    height: calc(30% - 48px);
  }
  .show-index[data-index-mode="list"] &, .show-about & {
    display: block;
  }
  &::-webkit-scrollbar { 
      display: none; 
  }
  .projects-index-inner {
    white-space: nowrap;
    height: 100%;
    display: flex;
    .project-index-holder {
      display: flex;
      height: 100%;
      float: left;
      .project-image {
        background: none !important;
      }
      &:hover {
        cursor: pointer;
        img {
          opacity: .5;
        }
      }
      &:first-child {
        margin-left: @unit;
        @media @mobile {
          margin-left: @unit/2;
        }
      }
    }
      .index-image {
        display: inline-block;
        height: 100%;
        background: none !important;
        .imageBanner {
          margin-right: @unit;
          @media @mobile {
            margin-right: @unit/2;
          }
          &:empty {
            margin-right: 0;
            display: none;
          }
        }
        .project-image-holder {
          height: 100%;
          .project-image {
            height: 100%;
          }
        }
        img {
          display: block;
          height: 100%;
          width: auto;
          
        }
      }
  }
}

#Index {
  display: none;
  min-height: @panel-h;
  .show-index & {
    display: block;
  }
  .index-content {
    margin: 0 @unit;
    margin-top: @bar-h;
    
    .global-text();
    .uppercase();
    @media @mobile {
      margin: 0 @unit/2;
      padding-top: @bar-h;
    }
    [data-index-mode="list"] & {
      padding-top: @bar-h;
    }
    [data-index-mode="thumbs"] & {
      //margin-top: @bar-h;
      padding-bottom: @bar-h - @unit;
    }
    .rte {
      ul {
        margin: 0;
        padding: 0;
        margin-right: -@unit;
        @media @mobile {
          margin-right: -@unit/2;
          //margin-top: @unit;
        }
        [data-index-mode="thumbs"] & {
          display: flex;
          flex-wrap: wrap;
        }
        li {
          list-style: none;
          margin-bottom: @unit;
          position: relative;
          @media @tablet {
            display: flex;
          }
          img {
            width: 100%;
            display: block;
            margin-bottom: @unit;
            @media @mobile {
              margin-bottom: @unit/2;
            }
          }
          
          [data-index-mode="thumbs"] & {
            width: 25%;
            margin-bottom: 0;
            &:hover {
              opacity: 1;
              img { opacity: 0; }
              .index-title {
                display: block;
              }
            }
            @media @tablet {
              width: 50%;
            }
            span {
              display: block;
              @media @mobile {
                margin-right: @unit/2;
              }
            }
            .index-num,
            .index-date,
            .index-location,
            .index-type {
              display: none;
            }
            .index-title {
              //display: none;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: 0;
              text-align: center;
              width: calc(100% - 12px);
              margin: 0;
              z-index: 1;
              display: none;
            }
          }
          [data-index-mode="list"] & {
            .index-image {
              display: none;
            }
          }
          &:hover {
            opacity: .5;
            cursor: pointer;
          }
          span {
            display: inline-block;
            margin-right: @unit;
            @media @mobile {
              margin-right: @unit;
            }
            &.col-1 {
              width: @unit * 8;
              @media @mobile {
                width: auto;
              }
            }
            &.col-2 {
              width: @unit * 16;
              @media @tablet {
                width: 30%;
              }
              @media @mobile {
                width: 50%;
              }
            }
   
            &.index-type {
              @media @mobile {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}